import React from 'react'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { UnderDevelopmentPage } from './UnderDevelopmentPage';

function App() {
  return (
    <UnderDevelopmentPage />
  );
}

export default App;
